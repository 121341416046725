<template>

 <div class="layout-wrapper" > 
    <div class="section-inner">
      <div class="back-logo" ><img src="../assets/img/background_logo.svg" /></div>
      <div class="content-wrapper">
      <h1>La page que vous recherchez n'existe pas.</h1>
        <p>Vous avez peut-être mal tapé l'adresse ou la page a peut-être été déplacée.</p>
      </div>
    </div>

 </div>

</template>

<script>
export default {
  name: '404',
  props: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.layout-wrapper{height: 60vh;}
.layout-wrapper .section-inner{
display: flex;
height: 100%;
flex-direction: column;
align-content: center;
justify-content: center;}

.back-logo{
width: 85%;
top: -10%;
  left: -33%;
  position: absolute;}
.layout-wrapper .content-wrapper{
  position: relative;
display: flex;
flex-direction: column;
margin: auto;}
.layout-wrapper .content-wrapper p{
color: #e4e0da}
</style>
